import React, { useState } from 'react';
import { Container, Navbar, Nav, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <div className='call-us'>
        <FontAwesomeIcon icon={faPhone} className='phone' />Call Us Today!&nbsp;
        <a href="tel:6823786191" className="call-link">(682) 378-6191</a>
      </div>
      <Navbar className="custom-navbar" variant="dark" expand="lg" expanded={isOpen}>
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={NavLink} to="/" onClick={handleNavLinkClick}>HOME</Nav.Link>

              <Dropdown
                as={Nav.Item}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                show={showDropdown}
                className="nav-link"
              >
                <Dropdown.Toggle as={NavLink} to="/products" className="nav-link" style={{ marginLeft: '10px' }}>PRODUCTS</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={NavLink} to="/products/shingles" onClick={handleNavLinkClick}>Shingles</Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/products/underlayment" onClick={handleNavLinkClick}>Underlayment</Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/products/ventilation" onClick={handleNavLinkClick}>Ventilation</Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/products/flashing" onClick={handleNavLinkClick}>Flashing</Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/products/fasteners" onClick={handleNavLinkClick}>Fasteners</Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/products/accessories" onClick={handleNavLinkClick}>Accessories</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link as={NavLink} to="/about" onClick={handleNavLinkClick}>ABOUT</Nav.Link>
              <Nav.Link as={NavLink} to="/contact" onClick={handleNavLinkClick}>CONTACT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="logo-container">
        <div className="logo-content">
          <img className="logo" alt="logo" src="/images/Logo4.png" />
          <span className="logo-text">
            Alvarado Roofing Supply LLC
            <p className='logo-sub'>SHINGLES | MATERIALS | ACCESSORIES</p>
          </span>
        </div>
      </Container>
    </>
  );
}

export default Header;
