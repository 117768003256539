import React from 'react';
import './about.css'

export default function About() {
    return (
      <>
        <div className="about-image-container">
            <img className="about-img" src="/images/about.jpeg" alt="Roof" />
            <p className="about-title">About Us</p>
        </div>
        <br></br>
        <p className='quality-roofing-2'>A Roofing Supplier You Can Count On</p>
        <div className="about-summary-container">
          <p className='about-summary-title'>Residential Roofing</p>
          <p className='about-summary'>
            If you're searching for the premier supplier of residential roofing materials in the Dallas Fort Worth Metroplex, Alvarado Roofing Supply LLC stands out as your ultimate choice. We specialize in offering a comprehensive range of discounted residential roofing materials, ensuring that every aspect of your residential roofing project is met with top-quality solutions. Whether you're in need of materials for an upcoming renovation or seeking expert advice, our team is here to assist. Our selection includes a variety of shingles, from asphalt to architectural styles, designed to provide both aesthetic appeal and durability. We source our shingles from trusted manufacturers to ensure that you receive only the best in quality and performance. In addition to shingles, we offer a wide range of essential roofing materials and accessories, including underlayment, flashing, ridge vents, and more, all carefully selected to complement our shingle offerings and enhance the overall integrity of your roofing system. Our commitment to quality means you can rely on us for durable and reliable solutions that will stand the test of time.
          </p>
        </div>
        <img className='about-summary-roof' src="/images/residential-roofing.jpeg" alt="Roof" />

        <div className='about-box'>
            <p>"As a <b>trusted supplier of residential roofing materials in the Dallas Fort Worth Metroplex</b>, Alvarado Roofing Supply LLC is renowned for our commitment to quality products and exceptional customer service."</p>
            <img className='supplies-img' src="/images/supplies.jpg" alt="Supplies" />
        </div>

        <div className="about-summary-container-2">
          <p className='about-summary-title-2'>Your Roofing Partner</p>
          <p className='about-summary-2'>
          Our knowledgeable staff is always ready to provide personalized recommendations and insights to help you choose the right shingles, materials, and accessories for your specific project. We understand that each roofing project is unique, and we strive to offer tailored solutions that match your requirements and budget. For your convenience, we offer multiple ways to get in touch with us. Contact us today through our online platform for a quick quote, or visit our branch to discover firsthand the exceptional service and extensive product offerings that define our commitment to excellence. Choose Alvarado Roofing Supply LLC as your trusted partner for all your residential roofing needs, and experience the difference that quality and expertise can make.          </p>
        </div>
        <img className='about-summary-roof-2' src="/images/aboutroof.jpeg" alt="Roof" />
    </>
  );
}