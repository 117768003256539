import React from 'react';
import './catalog.css';

export default function Fasteners() {
  return (
    <>
        <div className="catalog-black-box">
            <p className="catalog-title">Fasteners</p>
        </div>

        <div className='product-container'>
          <div className='product-text'>
            <img className='product-image' src="/images/coil-nail.jpeg" alt="Roof" />
            <p>Coil Nail</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/tamko-product.jpg" alt="Roof" />
            <p>Cap Nail</p>
          </div>
        </div>
    </>
  );
}
