import React from 'react';
import './catalog.css';

export default function Underlayment() {
  return (
    <>
        <div className="catalog-black-box">
            <p className="catalog-title">Underlayment</p>
        </div>

        <div className='product-container'>
          <div className='product-text'>
            <img className='product-image' src="/images/peel-stick.jpeg" alt="Roof" />
            <p>Peel & Stick Cap / Base</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/synthetic-felt.jpeg" alt="Roof" />
            <p>Synthetic Felt</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/ice-water.jpeg" alt="Roof" />
            <p>Ice & Water</p>
          </div>
        </div>
    </>
  );
}
