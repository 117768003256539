import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './0_Components/Header';
import Footer from './0_Components/Footer'
import Home from './1_Home/Home';
import Products from './2_Products/Products';
import About from './3_About/About';
import Contact from './4_Contact/Contact';
import Shingles from './2_Products/Catalog/Shingles';
import Underlayment from './2_Products/Catalog/Underlayment';
import Ventilation from './2_Products/Catalog/Ventilation';
import Flashing from './2_Products/Catalog/Flashing';
import Fasteners from './2_Products/Catalog/Fasteners';
import Accessories from './2_Products/Catalog/Accessories';
import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/products' element={<Products />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="/products/shingles" element={<Shingles/>} />
        <Route path="/products/underlayment" element={<Underlayment/>} />
        <Route path="/products/ventilation" element={<Ventilation/>}></Route>
        <Route path="/products/flashing" element={<Flashing/>}></Route>
        <Route path="/products/fasteners" element={<Fasteners/>}></Route>
        <Route path="/products/accessories" element={<Accessories/>}></Route>
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
