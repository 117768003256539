import React from 'react';
import './catalog.css';

export default function Ventilation() {
  return (
    <>
        <div className="catalog-black-box">
            <p className="catalog-title">Ventilation</p>
        </div>

        <div className='product-container'>
          <div className='product-text'>
            <img className='product-image' src="/images/ridge-vent.jpg" alt="Roof" />
            <p>Ridge Vent</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/turtle-vent.jpeg" alt="Roof" />
            <p>Turtle Vent Metal</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/turbine.jpeg" alt="Roof" />
            <p>Turbine</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/dryer-vent.jpeg" alt="Roof" />
            <p>Dryer Vent 4"</p>
          </div>
        </div>
    </>
  );
}
