import React from 'react';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './footer.css'

export default function Footer() {
  return (
    <>
      <div class="footer">
        <div>
          <img className="logo-footer" alt="logo" src="/images/logofooter.png" />
          <div className='contact-footer'>
            <b>Business Hours</b>
            <br></br>
            <br></br>
            <p>Monday - Friday: 7:30 AM - 5:00 PM</p>
            <p>Saturday: 7:30 AM - 3 PM</p>
            <p>Sunday: Closed</p>
          </div>
          <div className='contact-footer'>
          <b>Contact Us</b>
          <br></br>
          <br></br>
            <a href="tel:6823786191" className="call-link"><p><FontAwesomeIcon icon={faPhone} className='icons-footer'/>(682) 378-6191</p></a>
            <a className='call-link' href='https://www.google.com/maps/dir//alvarado+roofing+supply+llc/@33.605447,-96.0009014,6z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864e5f73044c6b37:0x2a7d8688091fb978!2m2!1d-97.1528384!2d32.4255652?entry=ttu' target="_blank" rel="noopener noreferrer"><p><FontAwesomeIcon icon={faMapMarkerAlt} className='icons-footer'/>201 FM2738 Unit E, Alvarado, TX 76009</p></a>
            <a className='call-link' href="mailto:AlvaradoRoofingSupply@gmail.com" >
              <FontAwesomeIcon icon={faEnvelope} className='icons-footer'/>AlvaradoRoofingSupply@gmail.com</a>
          </div>
        </div>
        <div className='socials'>
          <a className='insta' href="https://www.instagram.com/alvaradoroofingsupply/"><FontAwesomeIcon icon={faInstagram}/></a>
        </div>
        <div className='rights'>
          <p>© 2024 All Rights Reserved | Alvarado Roofing Supply LLC</p>
          <p>Website by Jehad Ismail</p>
        </div>
      </div>
    </>
  );
}
