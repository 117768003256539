import React from 'react';
import './products.css';
import { Link } from 'react-router-dom';

export default function Products() {
  return (
    <>
        <div className="product-black-box">
            <p className="products-title">Products</p>
            <div className="product-summary-container">
                <img className='product-page-image' src="/images/product-page.jpeg" alt="Roof" />
                <div className='spacing'>
                    <p className='product-summary-title'>Product Catalog</p>
                    <p className='product-summary'>We offer an extensive range of roofing supplies to meet your every need. Our inventory includes materials, shingles, and accessories essential for any roofing project. If you don't find what you're looking for, we can easily place an order from our main warehouse and ensure timely delivery for your project. Count on us for comprehensive solutions and expert advice tailored to your roofing requirements.</p>
                </div>
            </div>
        </div>

        <div class="image-grid">
            <div class="product-item">
                <img class="grid-image" src="/images/tamko-product.jpg" alt=""></img>
                <Link to="/products/shingles">
                    <button className="shingles">Shingles</button>
                </Link>
            </div>

            <div class="product-item">
                <img class="grid-image" src="/images/tamko-underlayment.jpg" alt=""></img>
                <Link to="/products/underlayment">
                    <button className="shingles">Underlayment</button>
                </Link>
            </div>

            <div class="product-item">
                <img class="grid-image" src="/images/ventilation.jpeg" alt=""></img>
                <Link to="/products/ventilation">
                    <button className="shingles">Ventilation</button>
                </Link>
            </div>

            <div class="product-item">
                <img class="grid-image" src="/images/flashing.jpeg" alt=""></img>
                <Link to="/products/flashing">
                    <button className="shingles">Flashing</button>
                </Link>
            </div>

            <div class="product-item">
                <img class="grid-image" src="/images/fasteners.jpeg" alt=""></img>
                <Link to="/products/fasteners">
                    <button className="shingles">Fasteners</button>
                </Link>
            </div>

            <div class="product-item">
                <img class="grid-image" src="/images/accessories.jpeg" alt=""></img>
                <Link to="/products/accessories">
                    <button className="shingles">Accessories</button>
                </Link>
            </div>
        </div>

    </>
  );
}
