import React from 'react';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <div className="image-container">
          <img className="first-img" src="/images/homepage.jpeg" alt="Roof" />
          <p className="overlay-text">Get a Quote Today From Alvarado Roofing Supply</p>
          <Link to="/contact">
            <button className="overlay-button">CONTACT US</button>
          </Link>
      </div>

      <br></br>
      
      <div>
        <p className='quality-roofing'>Quality Roofing Starts Here</p>
        <div className='side-by-side'>
          <img className='summary-roof' src="/images/homes.jpeg" alt="Roof" />
          <div className="summary-container">
            <p className='summary-title'>Residential Roofing</p>
            <p className='summary'>If you're searching for the premier supplier of residential roofing materials
              in the Dallas Fort Worth Metroplex, Alvarado Roofing Supply LLC stands out as your ultimate choice. We specialize in offering
              a comprehensive range of discounted residential roofing materials, ensuring that every aspect of your 
              residential roofing project is met with top-quality solutions. Whether you're in need of materials for
              an upcoming renovation or seeking expert advice, our team is here to assist. Contact us today through
              our online platform for a quick quote, or visit our branch to discover firsthand the exceptional
              service and extensive product offerings that define our commitment to excellence.
            </p>
          </div>
        </div>
      </div>
      
      <div className='brand-box-title'>
        <p className='brand-title'>We Offer Brands You Can Trust</p>
      </div>

      <div class="brand-box">
        <div class="brands">
          <img style={{padding: "10px" }} src="/images/atlas.jpg" alt="Brand 1 Logo"></img>
        </div>
        <div class="brands">
          <img style={{ marginLeft: "5px", padding: "10px" }} src="/images/TAMKO Building Products LLC (logo) 1.5-inch color.png" alt="Brand 2 Logo" />
        </div>
        <div class="brands">
            <img src="/images/gaf.png" alt="Brand 3 Logo"></img>
        </div>
      </div>

      <div class="side-by-side">
        <div className='column'>
          <p className='title'>Top Notch Quality</p>
          <p className='description'>At Alvarado Roofing Supply LLC, we pride ourselves on delivering top-notch quality in every product we offer. Our commitment to excellence ensures that you receive only the finest roofing materials, crafted to withstand the test of time and elements. With rigorous quality control measures and a dedication to sourcing the best materials, we guarantee that your roofing project will not only meet but exceed industry standards. Experience the peace of mind that comes with choosing Alvarado Roofing Supply LLC for all your roofing needs, where top-notch quality is our promise to you.</p>
        </div>

        <div className='column'>
          <p className='title'>Budget-Friendly Pricing</p>
          <p className='description'>At Alvarado Roofing Supply LLC, we understand that quality roofing materials should not break the bank. That's why we offer budget-friendly pricing without compromising on excellence. Our extensive selection of affordable roofing products ensures you get the best value for your investment. With competitive pricing and tailored packages, we make it easy for you to stay within your budget while still receiving top-tier materials. Choose Alvarado Roofing Supply LLC for your roofing needs and experience exceptional quality at a price that fits your budget.</p>
        </div>
      </div>
      
      <div className='container'>
        <div className="quote-container">
          <p className="quote-title">GET A FREE QUOTE</p>

          <form action="/submit-quote.php" method="post">
              <input type="text" id="name" name="name" placeholder="Name *" required></input>
              <input type="text" id="email" name="email" placeholder="Email *" required></input>
              <input type="text" id="phone" name="phone" placeholder="Phone *" required></input>
              <input type="text" id="details" name="details" placeholder="Details *" required></input>
              <input type="submit" value="Submit" className='submit-button'></input>
          </form>

        </div>

        <div className='quote-contact-container'>
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faPhone} className='icons'/></p>
              <a href="tel:6823786191" className="call-link">
                <p className='quote-contact'>&nbsp;&nbsp;(682) 378-6191</p>
              </a>
          </div>
          
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faMapMarkerAlt} className='icons'/></p>
              <a className="call-link" href='https://www.google.com/maps/dir//alvarado+roofing+supply+llc/@33.605447,-96.0009014,6z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864e5f73044c6b37:0x2a7d8688091fb978!2m2!1d-97.1528384!2d32.4255652?entry=ttu' target="_blank" rel="noopener noreferrer">
                <p className='quote-contact'>201 FM2738 Unit E, Alvarado, TX 76009</p>
              </a>
          </div>
          
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faEnvelope} className='icons'/></p>
              <p className="quote-contact">
                  <a href="mailto:ars.roofing24@gmail.com">ars.roofing24@gmail.com</a>
              </p>
          </div>
        </div>
      </div>
    </>
  );
}
