import React, { useEffect } from 'react';

const GoogleMapComponent = ({ children }) => {
  useEffect(() => {
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const initMap = async () => {
      if (!window.google || !window.google.maps) {
        await loadScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDH1GrFf0BYFUhnv4NeP4o_6Q_a1DvR71w&libraries=maps,marker`);
      }

      const { Map } = await window.google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("map"), {
        center: { lat: 32.42555618286133, lng: -97.15279388427734 },
        zoom: 14,
        mapId: "4504f8b37365c3d0",
      });

      new AdvancedMarkerElement({
        map,
        position: { lat: 32.42555618286133, lng: -97.15279388427734 },
      });
    };

    initMap().catch((error) => {
      console.error("Error loading the Google Maps API", error);
    });
  }, []);

  return (
    <div className="map-container-wrapper">
      <div className="map-container" id="map"></div>
      {children}
    </div>
  );
};

export default GoogleMapComponent;
