import React from 'react';
import './catalog.css'; 

export default function Shingles() {
  return (
    <>
        <div className="catalog-black-box">
            <p className="catalog-title">Shingles</p>
        </div>

        <div className='product-container'>
          
          <div className='product-text'>
            <img className='product-image' src="/images/Roof-Colors.png" alt="Roof" />
            <p>Heritage Series Shingles</p>
            <div className='roof-colors'>
              <p>Black</p>
              <p>Oxford Grey</p>
              <p>Cedar</p>
              <p>Weathered Wood</p>
              <p>Hickory</p>
              <p>Slate</p>
            </div>
          </div>
        </div>
    </>
  );
}
