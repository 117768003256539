import React from 'react';
import './catalog.css';

export default function Accessories() {
  return (
    <>
        <div className="catalog-black-box">
            <p className="catalog-title">Accessories</p>
        </div>

        <div className='product-container'>
          <div className='product-text'>
            <img className='product-image' src="/images/starter.jpg" alt="Roof" />
            <p>Starter</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/rain-cap.jpeg" alt="Roof" />
            <p>Versa Rain Cap</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/paint-bucket.jpeg" alt="Roof" />
            <p>Paint</p>
          </div>

          <div className='product-text'>
            <img className='product-image' src="/images/caulking.jpeg" alt="Roof" />
            <p>Caulking</p>
          </div>
          
          <div className='product-text'>
            <img className='product-image' src="/images/osb.jpeg" alt="Roof" />
            <p>OSB 7/16</p>
          </div>
        </div>
    </>
  );
}
