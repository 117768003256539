import React from 'react';
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import GoogleMapComponent from '../0_Components/GoogleMapComponent';

export default function Contact() {
  return (
    <>
      <div className="about-image-container">
        <img className="about-img" src="/images/contact.jpeg" alt="Roof" />
        <p className="contact-title">Contact Us</p>
      </div>

        <div className='container'>
        <div className="quote-container">
          <p className="quote-title">GET A FREE QUOTE</p>
          <form action="/submit-quote.php" method="post">
              <input type="text" id="name" name="name" placeholder="Name *" required></input>
              <input type="text" id="email" name="email" placeholder="Email *" required></input>
              <input type="text" id="phone" name="phone" placeholder="Phone *" required></input>
              <input type="text" id="details" name="details" placeholder="Details *" required></input>
              <input type="submit" value="Submit" className='submit-button'></input>
          </form>
        </div>

        <div className='quote-contact-container'>
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faPhone} className='icons'/></p>
              <a href="tel:6823786191" className="call-link">
                <p className='quote-contact'>(682) 378-6191</p>
              </a>
          </div>
          
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faMapMarkerAlt} className='icons'/></p>
              <a className='quote-contact' href='https://www.google.com/maps/dir//alvarado+roofing+supply+llc/@33.605447,-96.0009014,6z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864e5f73044c6b37:0x2a7d8688091fb978!2m2!1d-97.1528384!2d32.4255652?entry=ttu' target="_blank" rel="noopener noreferrer">
                <p className='quote-contact'>201 FM2738 Unit E, Alvarado, TX 76009</p>
              </a>
          </div>
          
          <div className='quote-contact-item'>
              <p className='quote-contact-title'><FontAwesomeIcon icon={faEnvelope} className='icons'/></p>
              <p className="quote-contact">
                  <a href="mailto:ars.roofing24@gmail.com">ars.roofing24@gmail.com</a>
              </p>
          </div>
        </div>
      </div>

      <br></br>

      <div className="map-container-wrapper">
        <GoogleMapComponent>
            <a href='https://www.google.com/maps/dir//alvarado+roofing+supply+llc/@33.605447,-96.0009014,6z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864e5f73044c6b37:0x2a7d8688091fb978!2m2!1d-97.1528384!2d32.4255652?entry=ttu'  target="_blank" rel="noopener noreferrer">
                <button className='get-directions'>Get Directions</button>
            </a>
        </GoogleMapComponent>
      </div>
    </>
  );
}
